const getGreeting = (currentDate: Date) => {
  const currentHour = currentDate.getHours();
  if (currentHour < 12) {
    return 'Good Morning';
  }
  if (currentHour < 16) {
    return 'Good Afternoon';
  }
  return 'Good Evening';
};

export default getGreeting;
