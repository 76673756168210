import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import { DEFAULT_UNITS } from 'common/constants';
import ControlBarScrollButtons from 'components/ControlBarScrollButtons';
import ForecastDayTable from 'components/ForecastDayTable';
import MissingFavourites from 'components/MissingFavourites';
import { ForecastOutlookContext } from 'contexts/ForecastOutlookContext';
import { PageContext } from 'contexts/PageContext';
import { UserTypeContext } from 'contexts/UserTypeContext';
import type { UserFavorites } from 'types/userFavorites';

import { useTreatments } from 'utils/treatments';
import { SL_WEB_FORECASTER_CONTENT } from 'common/treatments';
import styles from './FavoritesForecastOutlook.module.scss';

interface Props {
  favorites: UserFavorites;
  favoritesError?: boolean;
  makeContextualHref: Function;
}

const defaultMissingArgs = {
  title: 'Surf Forecast',
  boldText: 'Looks a little empty in here.',
  normalText: 'Add some favorite spots to compare live conditions and forecasts.',
};

const FavoritesForecastOutlook: React.FC<Props> = ({
  favorites,
  favoritesError,
  makeContextualHref,
}) => {
  const { asPath } = useRouter();
  const treatments = useTreatments();
  const { userType } = useContext(UserTypeContext);
  const { pageName } = useContext(PageContext);
  const { forecasts, requestXForecasts } = useContext(ForecastOutlookContext);
  const [noFavorites, setNoFavorites] = useState(!favorites.length);
  const scrollRef = useRef(null);

  const missingFavouritesTitle =
    treatments?.[SL_WEB_FORECASTER_CONTENT] === 'on' ? undefined : defaultMissingArgs.title;

  const debouncedScrollHandler = useMemo(
    () =>
      debounce(
        (scrollDirection: string, action: string) => {
          trackEvent('Forecast Range Updated', {
            action,
            pageName,
            scrollDirection,
            uiElement: 'forecast outlook',
          });
        },
        1000,
        {
          leading: true,
          trailing: false,
        },
      ),
    [pageName],
  );

  const debouncedScrollHandlers = useMemo(
    () => ({
      left: (action: string) => debouncedScrollHandler('Left', action),
      right: (action: string) => debouncedScrollHandler('Right', action),
    }),
    [debouncedScrollHandler],
  );

  const onViewAllClicked = useCallback(
    () =>
      trackEvent('Clicked Link', {
        linkText: 'View All',
        pageName,
        linkLocation: 'Outlook',
        linkName: 'View All',
        path: asPath,
        url: `${window?.location.host}${asPath}`,
      }),
    [asPath, pageName],
  );

  const callToActionButton = useMemo(
    () => ({
      text: 'View All',
      link: makeContextualHref({ type: 'forecast' }),
      linkAs: '/favorites?type=forecast',
      onClickCallback: onViewAllClicked,
    }),
    [makeContextualHref, onViewAllClicked],
  );

  const forecastDayTableProps = useMemo(() => {
    let filteredForecasts;
    if (userType.isAnonymous) {
      filteredForecasts = forecasts?.data?.overview?.slice(0, 5);
    } else {
      const isForecastDataAvailable = (forecasts?.data?.overview?.length ?? 0) > 0;
      if (isForecastDataAvailable) {
        filteredForecasts = favorites
          ?.reduce((acc: any[], fav: any) => {
            const forecast = forecasts?.data?.overview?.find(
              (forecastOverview) => fav._id === forecastOverview.spotId,
            );
            if (forecast) {
              acc.push(forecast);
            }
            return acc;
          }, [])
          .slice(0, 5);
      }
    }

    return {
      scrollRef,
      forecasts: filteredForecasts,
      units: forecasts?.associated?.units || DEFAULT_UNITS,
      error:
        favoritesError ||
        (forecasts?.data.overview?.length !== 0 &&
          forecasts?.data.overview?.every((overviewRow) => !!overviewRow.error)),
    };
  }, [
    favorites,
    favoritesError,
    forecasts?.associated?.units,
    forecasts?.data.overview,
    userType.isAnonymous,
  ]);

  useEffect(() => {
    if (noFavorites && favorites && favorites?.length >= 1) {
      setNoFavorites(false);
    }
  }, [noFavorites, favorites]);

  useEffect(() => {
    requestXForecasts(favorites?.slice(0, 5), 5);
  }, [requestXForecasts, favorites, forecasts?.data]);

  return noFavorites ? (
    <section className="sl-section-container">
      <MissingFavourites
        title={missingFavouritesTitle}
        boldText={defaultMissingArgs.boldText}
        normalText={defaultMissingArgs.normalText}
      />
    </section>
  ) : (
    <section
      className={classNames(styles.outlook, 'sl-section-container')}
      data-testid="sl-favorites-forecast-outlook-container"
    >
      {!noFavorites && (
        <>
          <div className={styles.headerWrap}>
            <Typography variant="h2">Surf Forecast</Typography>
            <ControlBarScrollButtons
              callToActionButton={callToActionButton}
              scrollByPercent={80}
              shouldScroll
              buttonColor="#171717"
              scrollRef={scrollRef.current}
              debouncedScrollHandlers={debouncedScrollHandlers}
            />
          </div>
          <ForecastDayTable {...forecastDayTableProps} />
        </>
      )}
    </section>
  );
};

export default FavoritesForecastOutlook;
