import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { useSnapCarousel } from 'react-snap-carousel';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Button, IconButton } from '@mui/material';
import { slugify, trackEvent } from '@surfline/web-common';

import { ChevronLeft, ChevronRight } from 'components/Icons';
import SpotPreviewCard from 'components/SpotPreviewCard';
import { useMaxWidthTablet } from 'hooks/useMediaQueries';
import type { Units } from 'types/units';
import type { UserFavorite, UserFavorites } from 'types/userFavorites';

import styles from './FavoritesCarouselSnap.module.scss';

interface FavoritesCarouselSnapProps {
  favorites: UserFavorites;
  makeContextualHref: Function;
  units: Units;
}

const Slide: FunctionComponent<{
  favorite: UserFavorite;
  index: number;
  isActive: boolean;
  isMaxWidthTablet: boolean;
  onClickItem: (title: string, cardIndex: number, id: string) => void;
  snapPointIndexes: Set<number>;
  units: Units;
}> = ({ favorite, index, isActive, isMaxWidthTablet, onClickItem, snapPointIndexes, units }) => {
  const camera = favorite?.cameras?.[0];

  const listItemStyle = useMemo(
    () => ({
      scrollSnapAlign: snapPointIndexes?.has(index) ? 'start' : 'none',
    }),
    [index, snapPointIndexes],
  );

  const geo = useMemo(
    () => ({ lat: favorite.lat, lon: favorite.lon }),
    [favorite.lat, favorite.lon],
  );

  const currentWaveHeight = useMemo(
    () => ({
      human: favorite.waveHeight.human,
      max: favorite.waveHeight.max,
      min: favorite.waveHeight.min,
      plus: favorite.waveHeight.plus,
    }),
    [favorite.waveHeight],
  );

  const handleOnClick = useCallback(() => {
    onClickItem(favorite.name, index, favorite._id);
  }, [favorite.name, index, favorite._id, onClickItem]);

  return (
    <li
      className={styles.item}
      data-testid="favorites-carousel-snap-card"
      key={favorite._id}
      style={listItemStyle}
    >
      <SpotPreviewCard
        alwaysDisplaySmall={false}
        camera={camera}
        cardIndex={index}
        currentConditions={favorite.conditions.value}
        currentWaveHeight={currentWaveHeight}
        geo={geo}
        hideThumbnailOnMobile={false}
        id={favorite._id}
        insightsCameraId={favorite.insightsCameraId}
        isMobileView={isMaxWidthTablet}
        onClickHandler={handleOnClick}
        showCamInsightsIconAtSmallWidth
        sideThumbnail={false}
        stopStream={!isActive}
        title={favorite.name}
        units={units}
      />
    </li>
  );
};

const FavoritesCarouselSnap: FunctionComponent<FavoritesCarouselSnapProps> = ({
  favorites,
  makeContextualHref,
  units,
}) => {
  const { asPath, push } = useRouter();
  const isMaxWidthTablet = useMaxWidthTablet();
  const {
    activePageIndex,
    hasNextPage,
    hasPrevPage,
    next,
    pages,
    prev,
    refresh,
    scrollRef,
    snapPointIndexes,
  } = useSnapCarousel(favorites.length ? { initialPages: [[0]] } : undefined);

  const viewAllLabel = isMaxWidthTablet ? 'View all' : 'View all favorites';

  const onClickPrevious = useCallback(() => {
    prev();
  }, [prev]);

  const onClickNext = useCallback(() => {
    next();
  }, [next]);

  const onClickItem = useCallback(
    (title, cardIndex, id) => {
      trackEvent('Carousel Clicked', {
        carouselItem: title,
        carouselName: 'Favorites Carousel',
        category: 'kbyg',
        path: asPath,
        positionNumber: cardIndex,
        title: 'Home',
      });
      push(`/surf-report/${slugify(title)}/${id}`);
    },
    [asPath, push],
  );

  const contextualHref = useMemo(() => makeContextualHref({ type: 'live' }), [makeContextualHref]);

  const slides = useMemo(
    () =>
      favorites.map((favorite, index) => {
        // determines if the slide is visible and video should play
        const slidesPerPage = Math.ceil(favorites.length / pages.length);
        const isLastPage = activePageIndex === pages.length - 1;
        const lastPageStartIndex = (pages.length - 2) * slidesPerPage;
        const isActive = isLastPage
          ? index >= lastPageStartIndex
          : activePageIndex === Math.floor(index / slidesPerPage);

        return (
          <Slide
            favorite={favorite}
            index={index}
            isActive={isActive}
            isMaxWidthTablet={isMaxWidthTablet}
            key={favorite._id}
            onClickItem={onClickItem}
            snapPointIndexes={snapPointIndexes}
            units={units}
          />
        );
      }),
    [activePageIndex, favorites, isMaxWidthTablet, onClickItem, pages, snapPointIndexes, units],
  );

  const prevSlidesLength = useRef(slides.length);

  useEffect(() => {
    if (slides.length !== prevSlidesLength.current) {
      refresh();
      prevSlidesLength.current = slides.length;
    }
  }, [slides, refresh]);

  if (!favorites.length) return null;

  return (
    <Box className={classNames('sl-section-container', styles.wrapper)} component="section">
      <div className={styles.actions}>
        <Link href={contextualHref} as="/favorites?type=live" shallow>
          <Button
            className={styles.viewAllButton}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            size="small"
            variant="secondary"
          >
            {viewAllLabel}
          </Button>
        </Link>
        <IconButton
          className={styles.arrow}
          data-testid="favorites-carousel-snap-previous"
          disabled={!hasPrevPage}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={onClickPrevious}
          size="small"
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          className={styles.arrow}
          data-testid="favorites-carousel-snap-next"
          disabled={!hasNextPage}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          onClick={onClickNext}
          size="small"
        >
          <ChevronRight />
        </IconButton>
      </div>
      <ul className={styles.scrollContainer} ref={scrollRef}>
        {slides}
      </ul>
    </Box>
  );
};

export default FavoritesCarouselSnap;
